import './App.css'

import { DatePicker, Space, Timeline } from 'antd'
import { useState } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  padding: 40px;
  background: #ff0000;
`

const TimelineStyled = styled(Timeline)`
  font-weight: bold;
  color: #00ff00;
` as any

function App() {
  const [ dates, setDates ] = useState<string[]>([])

  return (
    <Wrapper>
      <Space direction="vertical">
        <DatePicker onChange={(_, dateString) => setDates((p) => [ ...p, dateString ])} />

        <TimelineStyled>
          { dates.map((date, index) => (
            <TimelineStyled.Item key={ index }>{date}</TimelineStyled.Item>
          ))}
        </TimelineStyled>
      </Space>
    </Wrapper>
  )
}

export default App
